import Vue from 'vue'

Vue.component('CardPost', {
  template: `
    <a :href="Url" class="card-post">
      <div class="card-post__img">
          <img v-if="ImageSource" class="card-post__image" alt="" :src="ImageSource" />
      </div>
      <div class="card-post__content">
          <ul class="card-post__meta">
              <li v-if="Tag" class="card-post__meta-item card-post__meta--tag">{{Tag}}</li>
              <li v-if="Date" class="card-post__meta-item card-post__meta--date">{{Date}}</li>
          </ul>
          <div class="card-post__title" v-html="Title"></div>
          <div v-if="Authors" class="card-post__authors" >{{Authors}}</div>
          <div v-if="Abstract" class="card-post__abstract" v-html="Abstract"></div>
      </div>
    </a>
  `,
  props: {
    ID: { type: String, default: '' },
    Title: { type: String, default: '' },
    Abstract: { type: String, default: '' },
    Date: { type: String, default: '' },
    Url: { type: String, default: '' },
    Authors: { type: String, default: '' },
    BlogFilters: { type: Array, default: () => [] },
    Tag: { type: String, default: '' },
    ImageSource: { type: String, default: '' }
  }
})

