import Vue from 'vue'
import scrollTo from '../mixins/scrollTo'
Vue.component('BlogListing', {
  props: {
    blogItems: { type: Array, default: () => [] },
    filters: { type: Array, default: () => [] },
    pageSize: { type: Number, default: 2 },
  },
  mixins: [scrollTo],
  data () {
    return {
      appliedFilter: null,
      pageNum: 1,
      resultsToShow: []
    }
  },
  computed: {
    appliedFilterLabel () {
      return this.appliedFilter
        ? this.appliedFilter.Title
        : ''
    },
    filteredTotalResults() {
      return (this.appliedFilter) 
        ? this.blogItems.filter(blogEntry => {
          const relationships = blogEntry.BlogFilters || []
          const appliedFilterID = this.appliedFilter.ID
          return relationships
            .map(item => item.ID || '') // give me just ID's
            .includes(appliedFilterID)
        })
        : this.blogItems
    },
    hasMore () {
      return this.resultsToShow < this.filteredTotalResults
    }
  },
  methods: {
    clearFilter () {
      this.appliedFilter = null
      this.newSearch()
    },
    resetPageNum () {
      this.pageNum = 1
    },
    handleNewFilterSearch () {
      this.newSearch()
      // Delay enough for filters to show on page. Needed for mobile
      window.setTimeout(() => {
        this.scrollToElement('.blog-listing', 1000)
      }, 100);
    },
    newSearch() {
      this.resetPageNum()
      this.resultsToShow = this.filteredTotalResults.slice(0, (this.pageNum * this.pageSize))
    },
    updateFilterResults() {
      this.resultsToShow = this.filteredTotalResults.slice(0, (this.pageNum * this.pageSize))
    },
    viewMore () {
      this.pageNum++
      this.updateFilterResults()
    }
  },
  mounted () {
    this.updateFilterResults()
  }
})